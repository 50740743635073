import { useEffect, useRef } from "react";

/**
 * If the TextField is part of a conditional render or inside a dialog that isn't open when the component first mounts,
 * the autoFocus prop may not work. In such cases, use a ref to manage focus manually.
 */
export const useInputFocusRef = () => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return inputRef;
};
