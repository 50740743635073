import {
    Credit,
    IncomeEntityDTO,
    IncomeFrequencyEnum,
} from "@fspringveldt/qf-budget-generated-api-types";
import { BaseIncomeOrExpenseBuilder } from "./BaseIncomeOrExpenseBuilder";
import { format } from "date-fns";
import { DEFAULT_DATE_FORMAT } from "../../utils/utils";

export class IncomeBuilder extends BaseIncomeOrExpenseBuilder<IncomeEntityDTO> {
    constructor(
        protected model: IncomeEntityDTO = {
            amount: 0,
            country: "",
            credits: [],
            frequency: IncomeFrequencyEnum.Weekly,
            isActive: false,
            name: "",
            startDate: format(new Date(), DEFAULT_DATE_FORMAT),
            isOwned: true,
        }
    ) {
        super();
    }

    static empty(): IncomeEntityDTO {
        return new IncomeBuilder().build();
    }

    static pivotData(): IncomeBuilder {
        return new IncomeBuilder();
    }

    withCredits(credits: Array<Credit>) {
        this.model.credits = credits;
        return this;
    }

    withFrequency(frequency: IncomeFrequencyEnum) {
        this.model.frequency = frequency;
        return this;
    }

    withGstPercent(gstPercent: number) {
        this.model.gstPercent = gstPercent;
        return this;
    }

    withWithholdingTaxPercent(withholdingTaxPercent: number) {
        this.model.withholdingTaxPercent = withholdingTaxPercent;
        return this;
    }

    withHoursPerDay(hoursPerDay: number) {
        this.model.hoursPerDay = hoursPerDay;
        return this;
    }
}
