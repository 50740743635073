export const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";

export const sanitiseDate = (
    date: Date | string | undefined
): Date | undefined => {
    console.log("Date", date);
    if (date === "undefined") return undefined;
    return typeof date === "string" ? new Date(date) : date;
};

export const validateEmail = (email: string): boolean => {
    const regexp = new RegExp(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    return regexp.test(email);
};
